import React, { Component } from 'react'
import Title from '../components/title'
import Input from '../components/input'
import Select from '../components/select'
import Button from '../components/button'
import Checkbox from '../components/checkbox'
import ExpandCollapse from '../components/expandCollapse'
import NewLayout from '../layout/newLayout'
import styles from '../styles/scss/pages/contactUs.module.scss'
import SEO from "../components/seo"
import { Formik, FieldArray } from "formik"
import { isArray as _isArray, find as _find } from "lodash"
import { signUp } from "../services/email"
import { sendContact } from "../services/contact"
import PageContents from '../components/pageContents'
import cn from "classnames"

export default class Index extends Component {

	state = {
		showExtra: false,
		showModal: false,
		submitted: false,
		selectedTopic: null,
		selectedBrand: null,
		selectedProduct: null,
		selectedRegion: null,
		selectedAge: null,
	};

	topics = [{
		value: 'product-compliment',
		text: 'Compliment of Product',
		collectProductInfo: true,
		collectRegion: false,
		allowSubmit: true
	}, {
		value: 'product-question',
		text: 'Question about Product',
		collectProductInfo: true,
		collectRegion: false,
		allowSubmit: true
	}, {
		value: 'product-problem',
		text: 'Problem with Product',
		collectProductInfo: true,
		collectRegion: false,
		allowSubmit: true
	}, {
		value: 'product-request',
		text: 'Product Request',
		collectProductInfo: false,
		collectRegion: false,
		allowSubmit: false
	}, {
		value: 'media',
		text: 'Media Opportunity/Partnership',
		collectProductInfo: false,
		collectRegion: false,
		allowSubmit: true
	}, {
		value: 'investor',
		text: 'Investor Relations',
		collectProductInfo: false,
		collectRegion: false,
		allowSubmit: true
	}, {
		value: 'distributor',
		text: 'Becoming a Distributor',
		collectProductInfo: false,
		collectRegion: true,
		allowSubmit: true
	}, {
		value: 'sponsorship',
		text: 'Sponsorship',
		collectProductInfo: false,
		collectRegion: false,
		allowSubmit: false
	}, {
		value: 'charity',
		text: 'Charitable Donation',
		collectProductInfo: false,
		collectRegion: false,
		allowSubmit: false
	}, {
		value: 'website',
		text: 'Web Site',
		collectProductInfo: false,
		collectRegion: false,
		allowSubmit: true
	}, {
		value: 'sticker',
		text: 'Sticker Request',
		collectProductInfo: false,
		collectRegion: false,
		allowSubmit: false
	}]

	brands = [{
		value: 'wd-40',
		text: 'WD-40',
		products: [
			"1-Gallon",
			"3 oz Handy Can",
			"8 oz Smart Straw",
			"11 oz Smart Straw",
			"12 oz Smart Straw",
			"14.4 oz EZ-REACH",
			"18 oz Big Blast Can",
			"No-Mess Pen",
			"No-Mess Pen with Custom Imprint",
			"20 oz Trigger Pro Non-Aerosol",
			"16 oz Industrial Size",
			"Specialist Carb/Throttle Body & Parts Cleaner",
			"Specialist Spray & Stay Gel Lubricant",
			"Specialist Rust Release Penetrant Spray",
			"Specialist Water Resistant Silicone Lubricant",
			"Specialist Protective White Lithium Grease",
			"Specialist Long-Term Corrosion Inhibitor",
			"Specialist Rust Remover Soak",
			"Specialist Dirt & Dust Resistant Dry Lube",
			"Specialist Machine & Engine Degreaser",
			"Specialist Industrial-Strength Degreaser",
			"Specialist Industrial-Strength Cleaner & Degreaser",
			"Specialist Electrical Contact Cleaner Spray",
			"Specialist Superior Performance True Multi-Purpose Grease",
			"Specialist Heavy-Duty High Temperature Grease",
			"Specialist Heavy-Duty Extreme Pressure Grease",
			"Specialist Marine Grade Water Resistant Grease",
			"Other"
		]
	}, {
		value: '3-in-one',
		text: '3-IN-ONE',
		products: [
			"RVcare Slide-Out Silicone Lube",
			"RVcare Window & Track Dry Lube",
			"Multi-Purpose Drip Oil",
			"Multi-Purpose Telescoping Spout",
			"Motor Oil",
			"Garage Door Lube",
			"Fast-Acting Penetrant",
			"All-Temp Silicone",
			"Dry Lube",
			"Pneumatic Tool Oil",
			"PTFE Lube",
			"Other"
		]
	}, {
		value: 'lava',
		text: 'Lava',
		products: [
			"Lava Bar",
			"Lava Bar Twin-Pack",
			"Lava Liquid",
			"Other"
		]
	}, {
		value: 'x-14',
		text: 'X-14',
		products: [
			"Automatic Toilet Bowl Cleaner - Bleach",
			"Automatic Toilet Bowl Cleaner - Blue plus Fragrance",
			"Mildew Stain Remover",
			"Other"
		]
	}, {
		value: '2000-flushes',
		text: '2000 Flushes',
		products: [
			"Bleach",
			"Blue Plus Bleach",
			"Blue Plus Detergents",
			"Other"
		]
	}, {
		value: 'carpet-fresh',
		text: 'Carpet Fresh',
		products: [
			"Powder Family",
			"Quick-Dry Foam Family",
			"Other"
		]
	}, {
		value: 'spot-shot',
		text: 'Spot Shot',
		products: [
			"14 oz. Aerosol",
			"14 oz. Pet Aerosol",
			"18 oz. Professional Aerosol",
			"22 oz. Pet Trigger",
			"22 oz. Trigger",
			"Other"
		]
	}, {
		value: 'wd-40-bike',
		text: 'WD-40 Bike',
		products: [
			"All-Purpose Bike Wash",
			"Chain Cleaner & Degreaser",
			"All-Conditions Chain Lube",
			"Dry Chain Lubricant",
			"Wet Chain Lubricant"
		]
	}, {
		value: 'wd-40-motorcycle',
		text: 'WD-40 Motorcycle',
		products: [
			"Total Wash 33 8 Oz",
			"Chain Cleaner 18 Oz",
			"Chain Lube 14 Oz",
			"Chain Wax 13 5 Oz",
			"Total Shine 12 Oz"
		]
	}]

	regions = [{
		value: 'us',
		text: 'US'
	}, {
		value: 'canada',
		text: 'Canada'
	}, {
		value: 'latin-america',
		text: 'Latin America'
	}, {
		value: 'europe-middleeast-africa',
		text: 'Europe/Middle East/Africa'
	}, {
		value: 'asiapacific-australia',
		text: 'Asia Pacific/Australia'
	}, {
		value: 'china',
		text: 'China'
	}]

	ages = [{
		value: 'Under 15',
		text: 'Under 15'
	}, {
		value: '15 to 17',
		text: '15 to 17'
	}, {
		value: '18 to 24',
		text: '18 to 24'
	}, {
		value: '25 to 34',
		text: '25 to 34'
	}, {
		value: '35 to 44',
		text: '35 to 44'
	}, {
		value: '45 to 54',
		text: '45 to 54'
	}, {
		value: '55 to 64',
		text: '55 to 64'
	}, {
		value: '65 or Older',
		text: '65 or Older'
	}]

	genders = [{
		value: 'Male',
		text: 'Male'
	}, {
		value: 'Female',
		text: 'Female'
	}]

	handleClickExtraInfo = () => {
		this.setState({
			showExtra: !this.state.showExtra
		})
	}

	render() {
		const { showExtra } = this.state
		const pg = this.props.pageContext.page
		const attached = pg.attachments.filter((att) => {
			return att.slug === 'thank-you-contact'
		})
		var thankyou = null
		if (attached.length > 0) {
			thankyou = attached[0]
		} else {
			throw new Error("thank-you-contact is not attached")
		}
		return (
			<NewLayout>
				{pg.seo.title && <h1 style={{ display: 'none' }}>{pg.seo.title}</h1>}
				<SEO page={pg} />
				<div className='d-flex flex-column flex-fill'>
					<div className={styles.container}>
						<>
							<div className={cn(styles.formItems, "row")}>
								<div className={styles.formContainer}>
									{
										this.state.submitted ?
											<PageContents content={thankyou.content} />
											:
											<>
												<PageContents content={pg.content} />
												<Formik
													initialValues={{
														firstName: "",
														lastName: "",
														email: "",
														confirmEmail: "",
														lists: [],
														topic: null,
														brand: null,
														product: null,
														upc: "",
														lotCode: "",
														addr1: "",
														addr2: "",
														city: "",
														state: "",
														zip: "",
														country: "",
														age: "",
														gender: "",
														company: "",
														message: "",
														phone: "",
														fax: "",
														region: null,
														group: "contactUs"
													}}
													validate={values => {
														var errors = []
														//
														if (values.firstName === "") {
															errors.push({ field: "firstName", message: "First Name is required" })
														}
														//
														if (values.lastName === "") {
															errors.push({ field: "lastName", message: "Last Name is required" })
														}

														//
														if (values.email === "") {
															errors.push({ field: "email", message: "Email is required" })
														} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
															errors.push({ field: "email", message: "Invalid email address" })
														}

														//
														if (values.confirmEmail === "") {
															errors.push({ field: "confirmEmail", message: "Confirm Email is required" })
														} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.confirmEmail)) {
															errors.push({ field: "confirmEmail", message: "Invalid confirmation email address" })
														} else if (values.email !== values.confirmEmail) {
															errors.push({ field: "confirmEmail", message: "Email and Confirm Email must match" })
														}

														if (values.phone === "") {
															errors.push({
																field: "phone",
																message: "Mobile Phone Number is required",
															})
														}

														//
														if (values.topic === null) {
															errors.push({ field: "topic", message: "Please select a topic" })
														}

														if (values.topic !== null && this.state.selectedTopic.collectProductInfo && values.brand === null) {
															errors.push({ field: "brand", message: "Please select a brand" })
														}

														if (values.topic !== null && this.state.selectedTopic.collectProductInfo && values.brand !== null && values.product === null) {
															errors.push({ field: "product", message: "Please select a product" })
														}

														if (this.state.selectedTopic && values.upc === "" && this.state.selectedTopic.collectProductInfo && values.brand !== null && values.product !== null) {
															errors.push({ field: "upc", message: "Please enter the 12 digit UPC" })
														}

														if (this.state.selectedTopic && values.topic !== null && this.state.selectedTopic.collectRegion && values.region === null) {
															errors.push({ field: "region", message: "Please select a region" })
														}

														if (values.message === null || values.message === "") {
															errors.push({ field: "message", message: "Please enter a comment, question or request" })
														}

														return errors
													}}
													onSubmit={(values, { setSubmitting }) => {

														this.setState({
															showModal: true
														})
														sendContact(values).then((result) => {
															if (values.lists.length > 0) {
																signUp({
																	firstName: values.firstName,
																	lastName: values.lastName,
																	email: values.email,
																	phone: values.phone,
																	lists: values.lists,
																	industry: '',
																	industrytime: '',
																	birthday: '',
																	userplus18yearsold: (values.age !== '15 to 17' && values.age !== 'Under 15') ? true : false,
																	confirmation: true,
																	optSubject: '',
																	optMessage: '',
																	signupFormID: 1828532,
																	formIdentifier: 'contact'
																}).then((result) => {
																	setSubmitting(false)
																	this.setState({
																		submitted: true,
																		showModal: false
																	}, () => {

																	})
																})
															} else {
																setSubmitting(false)
																this.setState({
																	submitted: true,
																	showModal: false
																}, () => {

																})
															}
														}).catch((err) => {
															console.log('ERROR!')
															console.log(err)
															setSubmitting(false)
															this.setState({
																showModal: false
															})
														})
													}}>
													{(props) => (
														<form className={styles.form} onSubmit={props.handleSubmit}>
															<div>
																<div className={styles.row}>
																	<div className="col-sm-6" style={{ paddingLeft: '0px' }}>
																		<Select
																			title="Topic (required)"
																			titleClassName={styles.selectTitle}
																			noMargin
																			className={styles.select}
																			placeholder='Select a topic'
																			values={this.topics}
																			onChange={(e) => {
																				this.setState({
																					selectedTopic: _find(this.topics, { value: e.value }),
																					selectedBrand: null,
																					selectedProduct: null,
																					selectedRegion: null
																				}, () => {
																					props.setFieldValue('topic', e.value)
																					props.setFieldValue('brand', null)
																					props.setFieldValue('product', null)
																				})
																			}}
																			onBlur={props.handleBlur}
																			value={props.values.topic}
																		/>
																	</div>
																	<div className={cn("col-sm-6", { [`${styles.hidden}`]: (this.state.selectedTopic === null || !this.state.selectedTopic.collectProductInfo) })} style={{ paddingLeft: '0px' }}>
																		<Select
																			title="Brand (Required)"
																			titleClassName={styles.selectTitle}
																			noMargin
																			className={cn(styles.select)}
																			placeholder='Select a brand'
																			values={this.brands}
																			onChange={(e) => {
																				this.setState({
																					selectedBrand: _find(this.brands, { value: e.value }),
																					selectedProduct: null
																				}, () => {
																					props.setFieldValue('brand', e.value)
																					props.setFieldValue('product', null)
																				})
																			}}
																			onBlur={props.handleBlur}
																			value={props.values.brand}
																		/>
																	</div>
																	<div className={cn("col-sm-6", { [`${styles.hidden}`]: (this.state.selectedTopic === null || !this.state.selectedTopic.collectRegion) })} style={{ paddingLeft: '0px' }}>
																		<Select
																			title="Region (Required)"
																			titleClassName={styles.selectTitle}
																			noMargin
																			className={cn(styles.select)}
																			placeholder='Select a region'
																			values={this.regions}
																			onChange={(e) => {
																				this.setState({
																					selectedRegion: _find(this.regions, { value: e.value })
																				}, () => {
																					props.setFieldValue('region', e.value)
																				})
																				if (e.value === 'us') {
																					window.open(
																						'https://wd40.my.site.com/distributorsurvey/s/',
																						'_blank'
																					);
																					window.location.href = '/confirm/';
																				}
																				if (this.state.selectedTopic.value === 'distributor' && e.value === 'canada') {
																					window.open(
																						'https://wd40.my.site.com/distributorsurveyca/s/',
																						'_blank'
																					);
																				}
																			}}
																			onBlur={props.handleBlur}
																			value={props.values.brand}
																		/>
																	</div>
																</div>
															</div>
															<div>
																<div className={styles.row}>
																	<Input
																		name="firstName"
																		title="First Name"
																		placeholder="John"
																		className={styles.input}
																		onChange={props.handleChange}
																		onBlur={props.handleBlur}
																		value={props.values.firstName}
																	/>
																	<Input
																		name="lastName"
																		title="Last Name"
																		placeholder="Doe"
																		className={styles.input}
																		onChange={props.handleChange}
																		onBlur={props.handleBlur}
																		value={props.values.lastName}
																	/>
																</div>
																<div className={styles.row}>
																	<Input
																		name="email"
																		title="Email"
																		placeholder="jdoe@email.com"
																		className={styles.input}
																		onChange={props.handleChange}
																		onBlur={props.handleBlur}
																		value={props.values.email}
																	/>
																	<Input
																		name="confirmEmail"
																		title="Confirm Email"
																		placeholder="jdoe@email.com"
																		className={styles.input}
																		onChange={props.handleChange}
																		onBlur={props.handleBlur}
																		value={props.values.confirmEmail}
																	/>
																</div>
																<div className={styles.row}>
																	<Input
																		name="phone"
																		title="Phone Number"
																		phone="true"
																		placeholder="(000) 000-0000"
																		className={styles.input}
																		onChange={props.handleChange}
																		onBlur={props.handleBlur}
																		value={props.values.phone}
																	/>
																</div>
															</div>
															<div>
																<div className={cn(styles.rowExpand)}>
																	<ExpandCollapse title='Additional information' inverted onClick={this.handleClickExtraInfo.bind(this)} />
																</div>
																{showExtra && (
																	<div>
																		<div className={styles.row}>
																			<Input
																				name="addr1"
																				title='Address'
																				className={styles.input}
																				onChange={props.handleChange}
																				onBlur={props.handleBlur}
																				value={props.values.addr1} />
																			<Input
																				name="addr2"
																				title='APT/Suite #'
																				className={styles.input}
																				onChange={props.handleChange}
																				onBlur={props.handleBlur}
																				value={props.values.addr2} />
																		</div>
																		<div className={styles.row}>
																			<Input
																				name="city"
																				title='City'
																				className={styles.input}
																				onChange={props.handleChange}
																				onBlur={props.handleBlur}
																				value={props.values.city} />
																			<Input
																				name="state"
																				title='State'
																				className={styles.input}
																				onChange={props.handleChange}
																				onBlur={props.handleBlur}
																				value={props.values.state} />
																		</div>
																		<div className={styles.row}>
																			<Input
																				name="zip"
																				title='Zip'
																				className={styles.input}
																				onChange={props.handleChange}
																				onBlur={props.handleBlur}
																				value={props.values.zip} />
																			<Input
																				name="country"
																				title='Country'
																				className={styles.input}
																				onChange={props.handleChange}
																				onBlur={props.handleBlur}
																				value={props.values.country} />
																		</div>
																		<div className={styles.row}>
																			<Input
																				phone="true"
																				name="phone"
																				title='Phone Number'
																				className={styles.input}
																				onChange={props.handleChange}
																				onBlur={props.handleBlur}
																				value={props.values.phone} />
																			<Input
																				phone="true"
																				name="fax"
																				title='Fax Number'
																				className={styles.input}
																				onChange={props.handleChange}
																				onBlur={props.handleBlur}
																				value={props.values.fax} />
																		</div>
																		<div className={styles.row}>
																			<div className="col-sm-6" style={{ paddingLeft: '0px' }}>
																				<Select
																					title="Age Range"
																					titleClassName={styles.selectTitle}
																					noMargin
																					className={styles.select}
																					placeholder='Select an Age Range'
																					values={this.ages}
																					onBlur={props.handleBlur}
																					value={props.values.age}
																					onChange={(e) => {
																						this.setState({
																							selectedAge: _find(this.ages, { value: e.value }),
																						}, () => {
																							props.setFieldValue('age', e.value)
																						})
																					}}
																				/>
																			</div>
																			<div className="col-sm-6" style={{ paddingLeft: '0px' }}>
																				<Select
																					title="Gender"
																					titleClassName={styles.selectTitle}
																					noMargin
																					className={styles.select}
																					placeholder='Select a Gender'
																					values={this.genders}
																					onBlur={props.handleBlur}
																					value={props.values.gender}
																				/>
																			</div>
																		</div>
																		<div className={styles.row}>
																			<Input
																				name="company"
																				title='Company Name'
																				className={styles.input}
																				onChange={props.handleChange}
																				onBlur={props.handleBlur}
																				value={props.values.company} />
																		</div>
																	</div>
																)}
															</div>
															<div>
																<div className={cn(styles.row, { [`${styles.hidden}`]: (this.state.selectedBrand === null) })}>
																	<div className="col-sm-6" style={{ paddingLeft: '0px' }}>
																		<Select
																			title="Product (Required)"
																			titleClassName={styles.selectTitle}
																			noMargin
																			className={cn(styles.select, styles.selectFull)}
																			placeholder='Select a product'
																			values={this.state.selectedBrand === null ? [] : this.state.selectedBrand.products}
																			onChange={(e) => {
																				this.setState({
																					selectedProduct: e.v
																				}, () => {
																					props.setFieldValue('product', e.v)
																				})
																			}}
																			onBlur={props.handleBlur}
																			value={props.values.product}
																		/>
																	</div>
																	<div className="col-sm-6" style={{ paddingLeft: '0px', width: '100%' }}>
																		<Input
																			upc="true"
																			name="upc"
																			title="12 Digit UPC"
																			placeholder="X-XXXXX-XXXXX-X"
																			className={styles.input}
																			onChange={props.handleChange}
																			onBlur={props.handleBlur}
																			value={props.values.upc}
																			style={{ width: '100%' }}
																		/>
																	</div>
																</div>
																<div className={cn(styles.row, { [`${styles.hidden}`]: (this.state.selectedBrand === null) })}>
																	<Input
																		name="lotCode"
																		title="Date/Lot Code"
																		placeholder="Found on the bottom of aerosol cans and on back of trigger products"
																		className={styles.input}
																		onChange={props.handleChange}
																		onBlur={props.handleBlur}
																		value={props.values.lotCode}
																		style={{ width: '100%' }}
																	/>
																</div>
																<div className={cn(styles.row, { [`${styles.hidden}`]: (this.state.selectedTopic !== null && !this.state.selectedTopic.allowSubmit) })}>
																	<Input
																		name="message"
																		title='Comment, Question or Request'
																		className={styles.textarea}
																		onChange={props.handleChange}
																		onBlur={props.handleBlur}
																		value={props.values.message} />
																</div>
																{
																	(this.state.selectedTopic !== null && !this.state.selectedTopic.allowSubmit) ?
																		<div className={cn(styles.row)}>
																			{
																				['product-request', 'sponsorship', 'sticker'].indexOf(this.state.selectedTopic.value) > -1 ?
																					<p>
																						WD-40 Company does not have a formal sponsorship program at this time and is not able to provide product or sticker donations. We wish both new WD-40® Brand fans and those who have been sponsored by WD-40 Company in the past best of luck in their endeavors.
																					</p> : <></>
																			}
																			{
																				this.state.selectedTopic.value === 'charity' ?
																					<p>
																						WD-40 Company sponsors and supports projects and programs that improve the quality of life in the communities we serve and where our employees live and work. Our areas of focus include education, health care, community development, and arts & culture. To learn more, please visit the <a href="/community">Community Involvement</a> page on our website. If you have questions or if you would like to submit an official request for assistance based on the guidelines listed, please send an email to <a href="mailto:communityinvolvement@WD40.com">communityinvolvement@WD40.com</a>. Thanks for reaching out.
																					</p> : <></>
																			}
																		</div>
																		:
																		<></>
																}
															</div>
															<div className={cn({ [`${styles.hidden}`]: (this.state.selectedTopic !== null && !this.state.selectedTopic.allowSubmit) })}>
																<div className={styles.checkboxes}>
																	<Title
																		value="Sign me up for emails for:"
																		className={
																			styles.titleCheckbox
																		}
																	/>
																	<FieldArray name="lists" render={arrayHelpers => (
																		<>
																			<Checkbox
																				name="checkBrand"
																				text={[
																					"WD-40",
																					<sup key={1}>
																						&reg;
																					</sup>,
																					" Brand Products",
																				]}
																				onClick={(checked) => {
																					checked ?
																						arrayHelpers.insert(props.values.lists.length, 17512397)
																						:
																						arrayHelpers.remove(props.values.lists.indexOf(17512397), 17512397)
																				}}
																			/>
																		</>
																	)} />
																</div>
																<div className={cn(styles.feedback, { [`${styles.hidden}`]: (this.state.selectedTopic !== null && !this.state.selectedTopic.allowSubmit) })}>
																	<ul>
																		{_isArray(props.errors) && props.errors.filter((f) => { return props.touched[f.field] }).map((e) => {
																			return <li key={e.field}>{e.message}</li>
																		})}
																	</ul>
																</div>
																<div className={cn(styles.row, { [`${styles.hidden}`]: (this.state.selectedTopic !== null && !this.state.selectedTopic.allowSubmit) })}>
																	<Button
																		text="Submit"
																		newBlue
																		submit
																		className={styles.input}
																	/>
																</div>
															</div>
														</form>
													)}
												</Formik>
											</>
									}
								</div>
								<div className={cn(styles.contactData, "col-sm-4")}>
									<div className={styles.data}>
										<div>
											<p>Phone:</p>
											<span>WD-40 Customer Relations</span>
											<span><a href="tel:18883247596">1.888.324.7596</a></span>
										</div>
										<div>
											<p>Mailing Address:</p>
											<span>WD-40 Customer Relations</span>
											<span>P.O. Box 308</span>
											<span>Budd Lake, NJ, 07828</span>
										</div>
										<div>
											<p>Investor Relations:</p>
											<span>Wendy Kelley</span>
											<span><a href="18883247596">1.888.324.7596</a></span>
											<span>
												<a href='mailto:investorrelations@wd40.com'>investorrelations@wd40.com</a>
											</span>
										</div>

										<div className={styles.textExtraInfo}>
											* All customer inquiries should be directed to Customer Relations at the phone number or mailing address listed. Product inquiries sent to this email address will not receive a response.
										</div>
									</div>
								</div>
							</div>
						</>
					</div>
				</div>
				{this.state.showModal &&
					<div className={cn(styles.customModal)}>
						<div className={cn(styles.modalContent)}>
							<div>
								<i className={cn('fa', 'fa-clock-o')}></i>
							</div>
							<div>
								<Title yellow>Please wait...</Title>
							</div>
						</div>
					</div>
				}
			</NewLayout>
		);
	}
}